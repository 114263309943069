import React from 'react';
import { Tooltip, TooltipProps } from '@mui/material';

import { colors, styled } from 'core/styles';

const MAX_PARTS_TO_DISPLAY = 3;

export function MarketName({
  marketName,
  tooltip = 'auto',
  withSpaces = false,
  tooltipProps,
  maxWidth,
}: {
  marketName: string;
  tooltip?: 'auto' | 'disable';
  tooltipProps?: Omit<TooltipProps, 'title' | 'open' | 'children'>;
  withSpaces?: boolean;
  maxWidth?: number;
}) {
  const nameParts = marketName.split('/');
  const isOverflow = nameParts.length > MAX_PARTS_TO_DISPLAY;
  return (
    <Tooltip
      title={marketName}
      open={!isOverflow || tooltip === 'disable' ? false : undefined}
      {...tooltipProps}
    >
      <NameBox maxWidth={maxWidth}>
        {nameParts
          .slice(0, MAX_PARTS_TO_DISPLAY)
          .map((part, index) => [
            <span key={index}>{part}</span>,
            <TokenNameDivider key={`${index}/`} withSpaces={withSpaces}>
              /
            </TokenNameDivider>,
          ])
          .flat()}
        {isOverflow && (
          <>
            <TokenNameDivider withSpaces={withSpaces}>/</TokenNameDivider>…
          </>
        )}
      </NameBox>
    </Tooltip>
  );
}

const NameBox = styled('span', { shouldForwardProp: prop => prop !== 'maxWidth' })<{
  maxWidth: number | undefined;
}>(({ maxWidth }) =>
  maxWidth
    ? {
        display: 'inline-block',
        maxWidth,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }
    : {},
);

const TokenNameDivider = styled('span', { shouldForwardProp: prop => prop !== 'withSpaces' })<{
  withSpaces: boolean;
}>(({ withSpaces }) => ({
  color: colors.blackOpacity[32],
  padding: withSpaces ? '0 0.2em' : 0,

  '&:last-child': {
    display: 'none',
  },
}));

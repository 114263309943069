import React from 'react';

import { MarketInfo } from 'domain/types';
import { getShortAddress } from 'utils/format';
import { assertNever } from 'utils/types';
import { styled } from 'core/styles';
import { MarketName } from 'components';

import { RenderProps } from './types';

export function Title({
  context,
  currentProtocol,
  currentMarket,
  fontSize,
}: RenderProps & { currentMarket: MarketInfo | null; fontSize: number }) {
  const { type, payload } = context;

  if (type === 'lending-account') {
    return <Root fontSize={fontSize}>{getShortAddress(payload.accountId, 6, 5)} Account</Root>;
  }

  if (type === 'lending-position') {
    return <Root fontSize={fontSize}>{getShortAddress(payload.accountId, 6, 5)} Position</Root>;
  }

  if (type === 'lending') {
    return <Root fontSize={fontSize}>{currentProtocol.fullName}</Root>;
  }

  if (type === 'lending-market') {
    return currentProtocol.isMultiMarket ? (
      <Root fontSize={fontSize}>
        <MarketName marketName={currentMarket?.marketName || 'Unknown market'} />
      </Root>
    ) : (
      <Root fontSize={fontSize}>{currentProtocol.fullName}</Root>
    );
  }

  if (type === 'lending-token') {
    return <Root fontSize={fontSize}>{payload.tokenSymbol.toUpperCase()}</Root>;
  }

  assertNever(type);
}

const Root = styled('span', { shouldForwardProp: prop => prop !== 'fontSize' })<{
  fontSize: number;
}>(({ theme, fontSize }) => ({
  fontFamily: theme.typography.secondaryFontFamily,
  fontSize,
  lineHeight: '28px',
  letterSpacing: '-0.5px',
}));

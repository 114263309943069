import { LendingInfo } from 'domain/types';
import { LayoutContextByType } from 'layouts/LendingLayout/types';
import { RouteUtils } from 'routes';
import { assertNever } from 'utils/types';
import { LENDING_TOKEN_BY_LENDING_SLUG } from 'env';
import { NavbarLink } from 'components';

export function getNavbarLinks({
  layoutContext,
  routeRedirectTo,
  currentProtocol,
}: {
  layoutContext: LayoutContextByType<'lending' | 'lending-market' | 'lending-token'>;
  routeRedirectTo: RouteUtils['redirectTo'];
  currentProtocol: LendingInfo;
}): NavbarLink[] {
  const { type, payload } = layoutContext;
  const { productSlug, versionSlug, network, isMultiMarket } = currentProtocol;

  const pOverview = {
    label: 'Overview',
    href: routeRedirectTo.protocol({ productSlug, versionSlug, network }).href,
  };
  const pRatings = {
    label: 'Ratings',
    href: routeRedirectTo.protocolRatings({ productSlug, versionSlug, network }).href,
  };
  const pStatistics = {
    label: 'Statistics',
    href: routeRedirectTo.protocolStats({ productSlug, versionSlug, network }).href,
  };
  const pPositions = {
    label: 'Positions',
    href: routeRedirectTo.protocolPositions({ productSlug, versionSlug, network }).href,
  };
  const pAssets = {
    label: 'Assets',
    href: routeRedirectTo.protocolAssets({
      network,
      productSlug,
      versionSlug,
    }).href,
  };

  if (type === 'lending') {
    const protocolToken = LENDING_TOKEN_BY_LENDING_SLUG[productSlug];
    const tokenLinks = protocolToken
      ? [
          {
            label: protocolToken.symbol.toUpperCase(),
            href: routeRedirectTo.protocolTokenStats({
              productSlug,
              versionSlug,
              network: protocolToken.network,
              tokenSymbol: protocolToken.symbol,
            }).href,
          },
        ]
      : [];
    return isMultiMarket
      ? [
          pOverview,
          pRatings,
          pStatistics,
          {
            label: 'Markets',
            href: routeRedirectTo.marketsRatings({ productSlug, versionSlug, network }).href,
          },
          ...tokenLinks,
        ]
      : [pOverview, pRatings, pAssets, pPositions, ...tokenLinks];
  }

  if (type === 'lending-market') {
    return isMultiMarket
      ? [
          {
            label: 'Overview',
            href: routeRedirectTo.market({
              marketId: payload.marketId,
              network,
              productSlug,
              versionSlug,
            }).href,
          },
          {
            label: 'Ratings',
            href: routeRedirectTo.marketRatings({
              marketId: payload.marketId,
              network,
              productSlug,
              versionSlug,
            }).href,
          },
          {
            label: 'Assets',
            href: routeRedirectTo.marketAssets({
              marketId: payload.marketId,
              network,
              productSlug,
              versionSlug,
            }).href,
          },
          {
            label: 'Positions',
            href: routeRedirectTo.marketPositions({
              marketId: payload.marketId,
              network,
              productSlug,
              versionSlug,
            }).href,
          },
        ]
      : [pOverview, pRatings, pStatistics, pPositions];
  }

  if (type === 'lending-token') {
    return [
      {
        label: 'Statistics',
        href: routeRedirectTo.protocolTokenStats({
          tokenSymbol: payload.tokenSymbol,
          network,
          productSlug,
          versionSlug,
        }).href,
      },
      {
        label: 'Collateral',
        href: routeRedirectTo.protocolTokenCollateral({
          tokenSymbol: payload.tokenSymbol,
          network,
          productSlug,
          versionSlug,
        }).href,
      },
      {
        label: 'Credit Line',
        href: routeRedirectTo.protocolTokenCreditLine({
          tokenSymbol: payload.tokenSymbol,
          network,
          productSlug,
          versionSlug,
        }).href,
      },
    ];
  }

  assertNever(type);
}

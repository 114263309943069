import { Observable, map } from 'rxjs';

import { protocolsApi } from 'api/modules';
import { LendingInfo, ProductSlug } from 'domain/types';

export type LendingLayoutData = {
  protocolsInfo: LendingInfo[];
};

export function getLendingLayoutData({
  productSlug,
}: {
  productSlug: ProductSlug;
}): Observable<LendingLayoutData> {
  return protocolsApi
    .getLendings$(true, { productSlug })
    .pipe(map(protocols => ({ protocolsInfo: protocols })));
}

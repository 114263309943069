import React from 'react';

import { LendingInfo } from 'domain/types';
import { colors, styled } from 'core/styles';
import { FloatingTitle } from 'layouts/components';

import { Navigation, PageTitle, RenderProps, Title, TitleIcon } from './components';
import { getCurrentMarket } from './components/utils';
import { LayoutContext } from './types';

export function LendingHeader({
  protocols,
  layoutContext,
  isCustomHost,
}: {
  protocols: LendingInfo[];
  layoutContext: LayoutContext;
  isCustomHost: boolean;
}) {
  const { type, payload } = layoutContext;

  if (type === 'global' || type === 'unknown') {
    return null;
  }

  const currentProtocol = protocols.find(
    protocol =>
      protocol.network === payload.network &&
      protocol.productSlug === payload.productSlug &&
      protocol.versionSlug === payload.versionSlug,
  );

  if (!currentProtocol) {
    return null;
  }

  const renderProps: RenderProps = {
    context: layoutContext,
    protocols,
    currentProtocol,
  };

  const currentMarket = getCurrentMarket({
    context: layoutContext,
    currentProtocol,
  });

  return (
    <NavigationContainer isCustomHost={isCustomHost}>
      <NavigationContent>
        <PageTitle {...renderProps} />
        <Navigation {...renderProps} />
      </NavigationContent>
      {type !== 'lending-account' && type !== 'lending-position' && (
        <FloatingTitle
          key={type}
          icon={<TitleIcon {...renderProps} currentMarket={currentMarket} iconSize={32} />}
          title={<Title {...renderProps} currentMarket={currentMarket} fontSize={16} />}
        />
      )}
    </NavigationContainer>
  );
}

const NavigationContainer = styled('div')<{
  isCustomHost: boolean;
}>(({ theme, isCustomHost }) => ({
  position: 'sticky',
  top: isCustomHost ? -124 : -84,
  background: theme.palette.background.default,
  borderBottom: `1px solid ${colors.blackOpacity[12]}`,
  zIndex: 3,
}));

const NavigationContent = styled('div')({
  width: '100%',
  maxWidth: 772,
  display: 'flex',
  flexDirection: 'column',
  padding: '32px 16px 0',
  margin: '0 auto',
});

'use client';

import React, { ReactNode } from 'react';

import { styled } from 'core/styles';
import { getLendingLayoutData } from 'api/selectors/pages';
import { useServerData } from 'utils/react';
import { Loading } from 'components';
import { useRouteUtils } from 'routes';
import { ProductSlug } from 'domain/types';

import { useLayoutContext } from './useLayoutContext';
import { LendingHeader } from './LendingHeader';

export function LendingLayout({
  data,
  productSlug,
  children,
}: {
  data: string;
  productSlug: ProductSlug;
  children: ReactNode;
}) {
  const pageDataRD = useServerData(
    () => getLendingLayoutData({ productSlug }),
    [productSlug],
    data,
  );
  const layoutContext = useLayoutContext();
  const { isCustomHost } = useRouteUtils();

  return (
    <Root>
      <Loading data={pageDataRD}>
        {({ protocolsInfo }) => (
          <LendingHeader
            protocols={protocolsInfo}
            layoutContext={layoutContext}
            isCustomHost={isCustomHost}
          />
        )}
      </Loading>
      <Content>{children}</Content>
    </Root>
  );
}

const Root = styled('div')({
  width: '100%',
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
});

const Content = styled('div')(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: 772,
  padding: '24px 16px 120px',
  margin: '0 auto',

  [theme.breakpoints.up('tabletSM')]: {
    padding: '32px 16px 160px',
  },
}));

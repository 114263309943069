import * as React from 'react';
import Blockies from 'react-blockies';
import { Token } from '@akropolis-web/primitives';

import { styled, colors, FontSize, getFontSizeCSS } from 'core/styles';
import { IconTuple } from 'components';
import { ProductSlug, VersionSlug, NetworkSlug } from 'domain/types';

type Props = {
  productSlug: ProductSlug;
  versionSlug: VersionSlug;
  network: NetworkSlug;
  marketId: string;
  tokens: Token[];
  fontSize?: FontSize;
};

const MAX_TOKENS_IN_ICON = 3;

export function MarketIcon(props: Props) {
  const { productSlug, versionSlug, network, marketId, tokens, fontSize = 'medium' } = props;

  return tokens.length > MAX_TOKENS_IN_ICON ? (
    <Root
      seed={productSlug + versionSlug + network + marketId}
      size={8}
      scale={16}
      bgColor={colors.silver}
      fontSize={fontSize}
    />
  ) : (
    <IconTuple tokens={tokens} />
  );
}

const Root = styled(Blockies, {
  shouldForwardProp: prop => prop !== 'fontSize',
})<{
  fontSize: FontSize;
}>(({ fontSize }) => ({
  display: 'block',
  borderRadius: '0.5em',
  width: `1em !important`,
  height: '1em !important',
  fontSize: getFontSizeCSS({ fontSize }),
}));

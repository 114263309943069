import React from 'react';

import { AddressIcon, MarketIcon, ProtocolIcon, TokenIcon } from 'components';
import { MarketInfo } from 'domain/types';
import { styled } from 'core/styles';
import { LENDING_TOKEN_BY_LENDING_SLUG } from 'env';

import { RenderProps } from './types';

export function TitleIcon({
  context,
  currentProtocol,
  currentMarket,
  iconSize,
}: RenderProps & { currentMarket: MarketInfo | null; iconSize: number }) {
  const { type, payload } = context;
  const { isMultiMarket, productSlug, versionSlug, network } = currentProtocol;

  if (type === 'lending-account') {
    return (
      <IconContainer iconSize={iconSize}>
        <AddressIcon address={payload.accountId} fontSize="inherit" />
      </IconContainer>
    );
  }

  if (type === 'lending' || (type === 'lending-market' && !isMultiMarket)) {
    return (
      <IconContainer iconSize={iconSize}>
        <ProtocolIcon productSlug={productSlug} fontSize="inherit" />
      </IconContainer>
    );
  }

  if (type === 'lending-market' && isMultiMarket && currentMarket) {
    return (
      <IconContainer iconSize={iconSize}>
        <MarketIcon
          productSlug={productSlug}
          versionSlug={versionSlug}
          network={network}
          marketId={currentMarket.vid}
          tokens={currentMarket.tokens}
          fontSize="inherit"
        />
      </IconContainer>
    );
  }

  if (type === 'lending-token') {
    const protocolToken = LENDING_TOKEN_BY_LENDING_SLUG[payload.productSlug];
    return protocolToken ? (
      <IconContainer iconSize={iconSize}>
        <TokenIcon token={protocolToken} fontSize="inherit" />
      </IconContainer>
    ) : null;
  }

  return null;
}

const IconContainer = styled('div', { shouldForwardProp: prop => prop !== 'iconSize' })<{
  iconSize: number;
}>(({ iconSize }) => ({
  fontSize: iconSize,
}));

import { LendingInfo } from 'domain/types';

import { LendingLayoutContext } from '../types';

export function getCurrentMarket({
  context,
  currentProtocol,
}: {
  context: LendingLayoutContext;
  currentProtocol: LendingInfo;
}) {
  const { type, payload } = context;
  if (type === 'lending-market' || type === 'lending-position') {
    return currentProtocol.marketByVid[payload.marketId] || null;
  }

  if (type === 'lending' && !currentProtocol.isMultiMarket) {
    return currentProtocol.markets[0];
  }

  return null;
}

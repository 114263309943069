import React from 'react';
import { usePathname } from 'next/navigation';
import * as R from 'ramda';

import { RouteUtils, useRouteUtils } from 'routes';
import { Navbar } from 'components';
import { getNetworkTvl, getVersionTvl } from 'domain/utils/constants';
import { VersionSlug, NetworkSlug, asVersionSlug, LendingInfo } from 'domain/types';

import { getNavbarLinks } from './utils';
import { RenderProps } from './types';

export function Navigation({ context, protocols, currentProtocol }: RenderProps) {
  const { type } = context;
  const { redirectTo } = useRouteUtils();
  const pathname = usePathname();

  if (type === 'lending-account' || type === 'lending-position') {
    return null;
  }

  const sortedLendings = Object.values(R.groupBy(a => a.productSlug + a.versionSlug, protocols))
    .sort(
      (a, b) =>
        getVersionTvl(b[0].productSlug, b[0].versionSlug) -
        getVersionTvl(a[0].productSlug, a[0].versionSlug),
    )
    .map(protocolsGroup =>
      protocolsGroup.sort(
        (a, b) =>
          getNetworkTvl(b.productSlug, b.versionSlug, b.network) -
          getNetworkTvl(a.productSlug, a.versionSlug, a.network),
      ),
    )
    .flat();
  const networkSwitcherProps =
    type === 'lending-token' || (type === 'lending-market' && currentProtocol.isMultiMarket)
      ? null
      : {
          networks: sortedLendings.map(protocol => {
            return {
              network: protocol.network,
              versionSlug: protocol.versionSlug,
              networkName: protocol.networkName,
              versionName:
                protocol.versionSlug === asVersionSlug('default')
                  ? ''
                  : `${protocol.versionSlug[0].toUpperCase()}${protocol.versionSlug.slice(1)}`,
              // eslint-disable-next-line no-nested-ternary
              href: getNetworkLink({
                nextLending: protocol,
                currentLending: currentProtocol,
                redirectTo,
                pathname,
              }),
            };
          }),
          currentNetwork: currentProtocol.network,
          currentVersion: currentProtocol.versionSlug,
        };

  return (
    <Navbar
      links={getNavbarLinks({
        routeRedirectTo: redirectTo,
        layoutContext: context,
        currentProtocol,
      })}
      networkSwitcherProps={networkSwitcherProps}
    />
  );
}

const getNetworkLink = ({
  currentLending,
  nextLending,
  redirectTo,
  pathname,
}: {
  nextLending: LendingInfo;
  currentLending: LendingInfo;
  redirectTo: RouteUtils['redirectTo'];
  pathname: string;
}) => {
  const { productSlug, versionSlug, network, markets } = nextLending;
  const {
    versionSlug: currentVersion,
    network: currentNetwork,
    markets: currentMarkets,
  } = currentLending;

  const currentIsMultiMarket = currentMarkets.length > 1;
  const nextIsMultiMarket = markets.length > 1;

  const paths = {
    overview: (n: NetworkSlug, v: VersionSlug) =>
      redirectTo.protocol({ productSlug, versionSlug: v, network: n }).href,
    ratings: (n: NetworkSlug, v: VersionSlug) =>
      redirectTo.protocolRatings({ productSlug, versionSlug: v, network: n }).href,
    assets: (n: NetworkSlug, v: VersionSlug) =>
      redirectTo.protocolAssets({ productSlug, versionSlug: v, network: n }).href,
    statistics: (n: NetworkSlug, v: VersionSlug) =>
      redirectTo.protocolStats({ productSlug, versionSlug: v, network: n }).href,
    positions: (n: NetworkSlug, v: VersionSlug) =>
      redirectTo.protocolPositions({ productSlug, versionSlug: v, network: n }).href,
    markets: (n: NetworkSlug, v: VersionSlug) =>
      redirectTo.marketsRatings({ productSlug, versionSlug: v, network: n }).href,
  };

  if (pathname === paths.ratings(currentNetwork, currentVersion))
    return paths.ratings(network, versionSlug);

  if (
    currentIsMultiMarket === nextIsMultiMarket &&
    pathname === paths.statistics(currentNetwork, currentVersion)
  )
    return paths.statistics(network, versionSlug);

  if (
    currentIsMultiMarket === nextIsMultiMarket &&
    pathname === paths.positions(currentNetwork, currentVersion)
  )
    return paths.positions(network, versionSlug);

  if (
    currentIsMultiMarket === nextIsMultiMarket &&
    pathname === paths.markets(currentNetwork, currentVersion)
  )
    return paths.markets(network, versionSlug);

  if (
    currentIsMultiMarket === nextIsMultiMarket &&
    pathname === paths.assets(currentNetwork, currentVersion)
  )
    return paths.assets(network, versionSlug);

  return paths.overview(network, versionSlug);
};

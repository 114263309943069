import React from 'react';

import { colors, styled } from 'core/styles';
import { MarketName, NavigationLink, ProtocolIcon } from 'components';
import { useRouteUtils } from 'routes';
import { MarketSubscribeButton } from 'features/subscriptions';

import { getCurrentMarket } from './utils';
import { RenderProps } from './types';
import { TitleIcon } from './TitleIcon';
import { Title } from './Title';
import { Description } from './Description';

export function PageTitle(props: RenderProps) {
  const { context, currentProtocol } = props;
  const { type } = context;
  const { productSlug, versionSlug, network, isMultiMarket } = currentProtocol;
  const currentMarket = getCurrentMarket({ context, currentProtocol });
  const { redirectTo } = useRouteUtils();

  const parentProtocol =
    type === 'lending' || (type === 'lending-market' && !isMultiMarket)
      ? null
      : {
          logo: <ProtocolIcon productSlug={currentProtocol.productSlug} fontSize="inherit" />,
          productName: currentProtocol?.fullName,
          linkParams: redirectTo.protocol({ productSlug, versionSlug, network }),
        };

  const parentMarket =
    type === 'lending-position' && isMultiMarket && currentMarket
      ? {
          marketName: currentMarket.marketName,
          linkParams: redirectTo.market({
            productSlug,
            versionSlug,
            network,
            marketId: currentMarket.vid,
          }),
        }
      : null;

  return (
    <Root>
      <Breadcrumbs>
        {parentProtocol && parentProtocol.productName && (
          <>
            <ProtocolIconContainer>{parentProtocol.logo}</ProtocolIconContainer>
            <NavigationLink
              label={
                <span>
                  <BreadcrumbsLink>{parentProtocol.productName}</BreadcrumbsLink> /
                </span>
              }
              {...parentProtocol.linkParams}
            />
          </>
        )}
        {parentMarket && parentMarket.marketName && (
          <NavigationLink
            label={
              <span>
                <BreadcrumbsLink>
                  <MarketName marketName={parentMarket.marketName} />
                </BreadcrumbsLink>
              </span>
            }
            {...parentMarket.linkParams}
          />
        )}
      </Breadcrumbs>
      <TitleBox>
        <TitleIcon {...props} currentMarket={currentMarket} iconSize={52} />
        <NameBox>
          <Title {...props} currentMarket={currentMarket} fontSize={28} />
          <Description {...props} currentMarket={currentMarket} />
        </NameBox>
        {(type === 'lending' || type === 'lending-market') && currentMarket && (
          <MarketSubscribeButton
            productSlug={productSlug}
            versionSlug={versionSlug}
            networkSlug={network}
            marketVid={currentMarket.vid}
          />
        )}
      </TitleBox>
    </Root>
  );
}

const Root = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  marginBottom: 25,
});

const TitleBox = styled('div')({
  display: 'flex',
  gap: 24,
});

const Breadcrumbs = styled('div')({
  display: 'flex',
  gap: 8,
  minHeight: 24,
  fontSize: 14,
});

const ProtocolIconContainer = styled('div')({
  fontSize: 24,
});

const BreadcrumbsLink = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.secondaryFontFamily,
  fontSize: 14,
  lineHeight: '14px',
  fontWeight: 500,
  letterSpacing: '-0.5px',
  paddingBottom: 2,
  borderBottom: `1px solid ${colors.white}`,
}));

const NameBox = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: 4,
  marginRight: 'auto',
});

import React from 'react';

import { MarketInfo } from 'domain/types';
import { plural } from 'utils/string';
import { assertNever } from 'utils/types';
import { styled } from 'core/styles';
import { hasMultiMarketLending } from 'domain/utils';

import { RenderProps } from './types';

export function Description({
  context,
  protocols,
  currentProtocol,
  currentMarket,
}: RenderProps & { currentMarket: MarketInfo | null }) {
  const { type } = context;

  if (type === 'lending-account' || type === 'lending-position') {
    return null;
  }

  const currentVersions = protocols.filter(p => p.versionSlug === currentProtocol.versionSlug);

  const networksCount = currentVersions.length;
  const { marketsCount, isMultiMarket } = currentProtocol;
  const assetsCount = currentMarket ? currentMarket.tokens.length : 0;

  const networksText = plural('network', 'networks', networksCount);
  const marketsText = plural('market', 'markets', marketsCount);
  const assetsText = plural('asset', 'assets', assetsCount);

  if (type === 'lending') {
    return hasMultiMarketLending(currentVersions) ? (
      <Root>
        {`Lending protocol · ${networksCount} ${networksText} · ${marketsCount} ${marketsText}`}
      </Root>
    ) : (
      <Root>
        {`Lending protocol · ${networksCount} ${networksText} · ${assetsCount} ${assetsText}`}
      </Root>
    );
  }

  if (type === 'lending-market') {
    return isMultiMarket ? (
      <Root>{`Market · ${assetsCount} ${assetsText}`}</Root>
    ) : (
      <Root>{`Lending protocol · ${networksCount} ${networksText} · ${assetsCount} ${assetsText}`}</Root>
    );
  }

  if (type === 'lending-token') {
    return <Root>Asset · Stablecoin</Root>;
  }

  assertNever(type);
}

const Root = styled('span')({
  fontSize: 12,
  lineHeight: '16px',
});

import React from 'react';
import { Token } from '@akropolis-web/primitives';
import { Tooltip } from '@mui/material';

import { styled } from 'core/styles';

import { TokenIcon } from '../TokenIcon';

const MAX_TOKENS_IN_ICON = 7;
const MORE_LABEL_LENGTH = 4; // token icons number

type Props = {
  tokens: Token[];
  withTooltip?: boolean;
};

export function IconTuple({ tokens, withTooltip = false }: Props) {
  const isOverflow = tokens.length > MAX_TOKENS_IN_ICON;
  const formattedTokens = isOverflow
    ? tokens.slice(0, MAX_TOKENS_IN_ICON - MORE_LABEL_LENGTH)
    : tokens;

  return (
    <Root>
      {formattedTokens.map((token, index) => (
        <MaybeTooltip key={token.address} withTooltip={withTooltip} title={token.symbol}>
          <IconContainer key={index}>
            <TokenIcon token={token} fontSize="inherit" />
          </IconContainer>
        </MaybeTooltip>
      ))}
      {isOverflow && <IconsMore>+{tokens.length - 3} more</IconsMore>}
    </Root>
  );
}

type MaybeTooltipProps = {
  children: JSX.Element;
  title: string;
  withTooltip: boolean;
};

function MaybeTooltip({ children, title, withTooltip }: MaybeTooltipProps) {
  return withTooltip ? <Tooltip title={title}>{children}</Tooltip> : children;
}

const Root = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const IconContainer = styled('div')({
  display: 'flex',
  '& + &': {
    marginLeft: '-0.2em',
  },
});

const IconsMore = styled('span')({
  fontSize: '0.75em',
  marginLeft: '0.25em',
});

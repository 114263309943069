'use client';

import { useParams } from 'routes';

import { LayoutContext } from './types';

export function useLayoutContext(): LayoutContext {
  const { marketId, network, productSlug, versionSlug, accountId, tokenSymbol } = useParams(false, [
    'productSlug',
    'versionSlug',
    'network',
    'marketId',
    'accountId',
    'tokenSymbol',
  ]);

  if (!productSlug && !network && !marketId && !accountId && !tokenSymbol) {
    return {
      type: 'global',
      payload: null,
    };
  }

  if (productSlug && versionSlug && network && accountId && marketId) {
    return {
      type: 'lending-position',
      payload: { productSlug, versionSlug, network, accountId, marketId },
    };
  }

  if (productSlug && versionSlug && network && tokenSymbol) {
    return {
      type: 'lending-token',
      payload: { productSlug, versionSlug, network, tokenSymbol },
    };
  }

  if (productSlug && versionSlug && network && marketId) {
    return {
      type: 'lending-market',
      payload: { productSlug, versionSlug, network, marketId },
    };
  }

  if (productSlug && versionSlug && network && accountId) {
    return {
      type: 'lending-account',
      payload: { productSlug, versionSlug, network, accountId },
    };
  }

  if (productSlug && versionSlug && network) {
    return {
      type: 'lending',
      payload: { productSlug, versionSlug, network },
    };
  }

  return { type: 'unknown', payload: null };
}
